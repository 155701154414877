/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from "react";

interface TimeoutFuncType {
  (): void;
}

export const useTimeout = (
  cb: TimeoutFuncType,
  timeout: number,
  dependency: any
): void => {
  useEffect(() => {
    if (!dependency) {
      return;
    }
    const time = setTimeout(cb, timeout);

    return () => {
      clearInterval(time);
    };
  }, [cb, timeout, dependency]);
};
