import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Button,
  styled,
} from "@mui/material";
import { types, context } from "../../tools";
import { useTranslation } from "react-i18next";

export const AdminPanel: React.FC<types.AdminPanelProps> = ({
  task,
  onStart,
  onEnd,
  onApply,
}) => {
  const { t } = useTranslation();
  const { voting, cards } = context.useRoom();
  const [selectedValue, setSelectedValue] = React.useState<string>(cards[0]);
  const onVotingStart = () => {
    onStart(task.id);
  };
  const onVotingEnd = () => {
    onEnd(task.id);
  };
  const onVotingApply = () => {
    onApply(task.id, selectedValue);
    setSelectedValue(cards[0]);
  };
  const isDefault = !task;
  return (
    <Box sx={{ my: 1 }}>
      <Grid container justifyContent="end" alignItems="center" spacing="5px">
        <Grid item xs={6} md order={{ xs: 4, md: 2 }}>
          <StyledButton
            onClick={onVotingStart}
            color="secondary"
            disabled={voting.status}
          >
            {t("room.adminPanel.start_voting")}
          </StyledButton>
        </Grid>
        <Grid item xs={6} md order={{ xs: 5, md: 3 }}>
          <StyledButton
            onClick={onVotingEnd}
            color="secondary"
            disabled={!voting.status}
          >
            {t("room.adminPanel.end_voting")}
          </StyledButton>
        </Grid>
        {!isDefault && (
          <>
            <Grid item xs={4} md={2.5} order={{ xs: 2, md: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="size-label" size="small" color="secondary">
                  {t("room.adminPanel.story_points")}
                </InputLabel>
                <Select
                  value={selectedValue}
                  label={t("room.adminPanel.story_points")}
                  onChange={(e: SelectChangeEvent) => {
                    setSelectedValue(e.target.value as types.SPType);
                  }}
                  size="small"
                  color="secondary"
                  labelId="size-label"
                  disabled={voting.status}
                >
                  {cards.map((e) => (
                    <MenuItem value={e} key={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} md={"auto"} order={{ xs: 3, md: 5 }}>
              <StyledButton
                onClick={onVotingApply}
                color="secondary"
                disabled={voting.status || !task}
                variant="outlined"
              >
                {t("room.adminPanel.apply")}
              </StyledButton>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
const StyledButton = styled(Button)`
  height: 100%;
`;
