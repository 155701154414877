import React from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Checkbox,
  styled,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { types, api } from "../../tools";
import { useMutation, useQuery } from "react-query";
import { JiraTasksGrid } from "./JiraTasksGrid";
import { useTranslation } from "react-i18next";
type SprintTypes = "active" | "future";
type SprintsType = {
  active: any[];
  future: any[];
};
const sprintTypeValues = ["active", "future"];
export const JiraView: React.FC<types.JiraViewProps> = ({
  selected,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [devServerStatus, setStatus] = React.useState("");
  const [rapid, setRapid] = React.useState("");
  const [team, setTeam] = React.useState("");
  const [sprintType, setSprintType] = React.useState<SprintTypes>("future");
  const [sprints, setSprints] = React.useState<SprintsType>({} as SprintsType);
  const [selectedSprint, setSelectedSprint] = React.useState<any>(undefined);
  const [data, setData] = React.useState<any>({});
  const [checkedStories, setCheckedStories] = React.useState(true);
  const [checkedSP, setCheckedSP] = React.useState(false);
  const [error, setError] = React.useState<string>(t("tasksComponent.proxy"));
  const { status, isLoading: statusLoading } = useQuery<any>(
    "available",
    api.getJiraDevServerStatus,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err) => setError(String(err)),
    }
  );
  const { mutate: mutateTasks, isLoading: isTasksLoading } = useMutation(
    api.getJiraData
  );
  const { mutate, isLoading } = useMutation(api.getSprints);
  React.useEffect(() => {
    setStatus(status);
  }, [status]);
  const handleClick = () => {
    mutate(
      { team: team, rapidView: rapid },
      {
        onSuccess: (sprints) => {
          setSprints(sprints);
          setSelectedSprint(sprints[sprintType][0].name);
        },
      }
    );
  };
  const onSprintSelect = () => {
    const sprint = sprints[sprintType].find((el) => el.name === selectedSprint);
    mutateTasks(
      { sprintId: sprint.id, rapidView: rapid },
      {
        onSuccess: (newData) => setData(newData),
      }
    );
  };
  const filterData = (tasks: any[]) => {
    let result = tasks;
    if (checkedStories) result = result.filter((e) => e.type === "Story");
    if (checkedSP) result = result.filter((e) => !e.storyPoint);
    return result;
  };
  return (
    <Grid container mt={2}>
      {statusLoading ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : devServerStatus !== "error" ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <TextField
                value={rapid}
                onChange={(e) => setRapid(e.target.value)}
                label={t("tasksComponent.rapid_view")}
                size="small"
                fullWidth
                autoComplete="off"
                color="secondary"
              />
            </Grid>
            <Grid item xs={7} md={2}>
              <TextField
                value={team}
                onChange={(e) => setTeam(e.target.value)}
                label={t("tasksComponent.team")}
                size="small"
                fullWidth
                autoComplete="off"
                color="secondary"
                helperText={t("tasksComponent.optional")}
              />
            </Grid>

            <Grid item xs={5} md={2}>
              <StyledButton
                onClick={handleClick}
                variant="outlined"
                color="secondary"
              >
                {t("tasksComponent.fetch_tasks")}
              </StyledButton>
            </Grid>
            {isLoading ? (
              <CircularProgress sx={{ mt: 1, mx: "auto" }} color="secondary" />
            ) : (
              ((sprints.active && sprints.active.length > 0) ||
                (sprints.future && sprints.future.length > 0)) && (
                <>
                  <Grid item xs={5} md={1.75}>
                    <Select
                      value={sprintType}
                      label={t("tasksComponent.sprint_type")}
                      onChange={(e: SelectChangeEvent) => {
                        setSprintType(e.target.value as SprintTypes);
                        setSelectedSprint(
                          sprints[e.target.value as SprintTypes][0].name
                        );
                      }}
                      size="small"
                      color="secondary"
                      labelId="size-label"
                    >
                      {sprintTypeValues.map((e, i) => (
                        <MenuItem value={e} key={e}>
                          {t(`tasksComponent.sprint_types.${e}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={7} md={2.25}>
                    <Select
                      value={selectedSprint}
                      label={t("tasksComponent.selected_sprint")}
                      onChange={(e: SelectChangeEvent) => {
                        setSelectedSprint(e.target.value);
                      }}
                      size="small"
                      color="secondary"
                      labelId="size-label"
                      fullWidth
                    >
                      {sprints[sprintType].map((e, i) => (
                        <MenuItem value={e.name} key={e.name}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledButton
                      onClick={onSprintSelect}
                      variant="outlined"
                      color="secondary"
                    >
                      {t("tasksComponent.fetch_tasks")}
                    </StyledButton>
                  </Grid>
                </>
              )
            )}
          </Grid>
          <Grid container>
            {isTasksLoading ? (
              <CircularProgress sx={{ mt: 1, mx: "auto" }} color="secondary" />
            ) : (
              <>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedStories}
                          onChange={() => setCheckedStories(!checkedStories)}
                          color="secondary"
                        />
                      }
                      color="secondary"
                      label={
                        <Typography color="secondary">
                          {t("tasksComponent.only_stories")}
                        </Typography>
                      }
                      sx={{ mx: "auto" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSP}
                          onChange={() => setCheckedSP(!checkedSP)}
                          color="secondary"
                        />
                      }
                      color="secondary"
                      label={
                        <Typography color="secondary">
                          {t("tasksComponent.without_sp")}
                        </Typography>
                      }
                      sx={{ mx: "auto" }}
                    />
                  </FormGroup>
                </Grid>
                {data.issues &&
                  data.issues.completed &&
                  data.issues.completed.length > 0 &&
                  filterData(data.issues.completed).length > 0 && (
                    <Grid item xs={12}>
                      <JiraTasksGrid
                        selected={selected}
                        onSelect={onSelect}
                        tasks={filterData(data.issues.completed)}
                        label={t("tasksComponent.completed")}
                      />
                    </Grid>
                  )}
                {data.issues &&
                  data.issues.opened &&
                  data.issues.opened.length > 0 &&
                  filterData(data.issues.opened).length > 0 && (
                    <Grid item xs={12}>
                      <JiraTasksGrid
                        selected={selected}
                        onSelect={onSelect}
                        tasks={filterData(data.issues.opened)}
                        label={t("tasksComponent.opened")}
                      />
                    </Grid>
                  )}
                {data.issues &&
                  data.issues.other &&
                  data.issues.other.length > 0 &&
                  filterData(data.issues.other).length > 0 && (
                    <Grid item xs={12}>
                      <JiraTasksGrid
                        selected={selected}
                        onSelect={onSelect}
                        tasks={filterData(data.issues.other)}
                        label={t("tasksComponent.other")}
                      />
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        </>
      ) : (
        <>{error}</>
      )}
    </Grid>
  );
};
const StyledButton = styled(Button)`
  margin-top: 2px;
`;
