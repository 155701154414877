import React from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { types } from "../../tools";
import { Close, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const TaskItem: React.FC<types.TaskItemProps> = ({
  task,
  onSave,
  onDelete,
  metrics = {},
  isEdit,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [name, setName] = React.useState(task.name ?? "");
  const [size, setSize] = React.useState(task.size ?? "");
  const [participation, setParticipation] = React.useState(
    task.participation ?? ""
  );
  const [risks, setRisks] = React.useState(task.risks ?? "");

  return (
    <Grid container spacing="5px">
      {!isEdit ? (
        <Grid item xs={12} md>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={t("tasksComponent.task_name")}
            size="small"
            color="secondary"
            fullWidth
            disabled={disabled}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onSave({ ...task, name })}
          >
            Save
          </Button>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md>
            <FormControl fullWidth>
              <InputLabel id="size-label" size="small">
                {t("tasksComponent.task_size")}
              </InputLabel>
              <Select
                value={size}
                label={t("tasksComponent.task_size")}
                onChange={(e: SelectChangeEvent) => {
                  setSize(e.target.value);
                  onSave({
                    ...task,
                    size: e.target.value,
                  });
                }}
                size="small"
                color="secondary"
                labelId="size-label"
                disabled={disabled}
              >
                {Object.keys(metrics).map((e, i) => (
                  <MenuItem value={e} key={i}>
                    {t(`tasksComponent.tasks_metrics.${e}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="participation-label" size="small">
                {t("tasksComponent.task_parts")}
              </InputLabel>
              <Select
                value={participation}
                label={t("tasksComponent.task_parts")}
                onChange={(e: SelectChangeEvent) => {
                  setParticipation(e.target.value);
                  onSave({
                    ...task,
                    participation: e.target.value,
                  });
                }}
                size="small"
                color="secondary"
                labelId="participation-label"
                disabled={disabled}
              >
                {Object.keys(metrics).map((e, i) => (
                  <MenuItem value={e} key={i}>
                    {t(`tasksComponent.tasks_metrics.${e}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md>
            <FormControl fullWidth>
              <InputLabel id="risks-label" size="small">
                {t("tasksComponent.task_risks")}
              </InputLabel>
              <Select
                value={risks}
                label={t("tasksComponent.task_risks")}
                onChange={(e: SelectChangeEvent) => {
                  setRisks(e.target.value);
                  onSave({
                    ...task,
                    risks: e.target.value,
                  });
                }}
                size="small"
                color="secondary"
                labelId="risks-label"
                disabled={disabled}
              >
                {Object.keys(metrics).map((e, i) => (
                  <MenuItem value={e} key={i}>
                    {t(`tasksComponent.tasks_metrics.${e}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      {!disabled && (
        <Grid xs={2} md={1} item textAlign="center">
          <IconButton aria-label="close" onClick={() => onDelete(task.id)}>
            <Delete color="secondary" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
