import React from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { context, hooks, types } from "../../tools";
import { useTranslation } from "react-i18next";

export const VotePanel: React.FC<types.VotePanelProps> = ({
  task,
  onVote,
  onEnd,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { admins, voting, votingData, cards } = context.useRoom();
  const [user] = hooks.useUser();
  const [selected, setSelected] = React.useState(votingData[user.id]);
  const votingHandler = (event: React.MouseEvent<HTMLElement>) => {
    const value = event.currentTarget.innerText;
    setSelected(value);
    onVote(value);
  };
  const onVotingEnd = () => {
    onEnd(task.id);
  };
  return (
    <Container
      component="footer"
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "20px",
        margin: "auto",
        width: "100%",
        maxWidth: "720px !important",
        backgroundColor: theme.palette.background.default,
        border: 1,
        borderRadius: 2,
        zIndex: 15,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: 1 }}>
            {t("room.voting_panel")}
          </Typography>
        </Grid>
        {cards.map((e) => (
          <Grid item xs={2} md sx={{ p: 2 }} key={e} justifyContent="center">
            <Paper
              sx={{
                width: "35px",
                py: 2,
                px: 1,
                border: e === selected ? 2 : 1,
                m: "auto",
                fontWeight: e === selected ? "bold" : "normal",
                borderColor:
                  e === selected
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                color:
                  e === selected
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
              }}
              onClick={votingHandler}
            >
              {e}
            </Paper>
          </Grid>
        ))}
        {admins.some((a) => a.id === user.id) && (
          <Grid item xs={12} md order={{ xs: 5, md: 3 }}>
            <StyledButton
              onClick={onVotingEnd}
              color="secondary"
              disabled={!voting.status}
            >
              {t("room.adminPanel.end_voting")}
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
const StyledButton = styled(Button)`
  margin-bottom: 8px;
`;
