import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { context, hooks, types } from "../../tools";
import { useTranslation } from "react-i18next";
import { Edit, ExpandMore, Save } from "@mui/icons-material";
import { TaskItem } from "../TasksListComponent/TaskItem";
import { AdminPanel } from "./AdminPanel";
import { VotePanel } from "./VotePanel";
import { Recommended } from "./Recommended";
export const DataItem: React.FC<types.DataItemProps> = ({
  task,
  expanded,
  onExpand,
}) => {
  const { t } = useTranslation();
  const {
    metrics,
    admins,
    voting,
    taskEdit,
    tasks,
    tasksUpdating,
    applyVoting,
    endVoting,
    startVoting,
    votingAction,
  } = context.useRoom();
  const getColor = (value: string) =>
    value && metrics[value] ? metrics[value] : "transparent";
  const [user] = hooks.useUser();
  const onSave = (newTask: types.Task) => {
    const { size, risks, participation } = newTask;
    // eslint-disable-next-line no-self-compare
    if (JSON.stringify(newTask) !== JSON.stringify(task))
      taskEdit(newTask.id, {
        size,
        risks,
        participation,
      });
  };
  const onDelete = (task: string) => {
    tasksUpdating(tasks.filter((t) => t.id !== task));
  };
  const onStart = React.useCallback(
    (id: string) => {
      startVoting(id);
    },
    [startVoting]
  );

  const onEnd = React.useCallback(
    (id: string) => {
      endVoting(id);
    },
    [endVoting]
  );
  const onApply = React.useCallback(
    (id: string, value: string) => {
      applyVoting(id, value);
    },
    [applyVoting]
  );
  const onVote = (value: string) => {
    votingAction(user.id, value);
  };
  return (
    <>
      {!onExpand ? (
        <Grid container sx={{ mb: 1, borderBottom: 1, paddingX: "16px" }}>
          <Grid item xs={3}>
            <Typography color="secondary" variant="h6" fontWeight="bold">
              {t("room.name")}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="secondary" variant="h6" fontWeight="bold">
              {t("room.size")}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="secondary" variant="h6" fontWeight="bold">
              {t("room.parts")}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="secondary" variant="h6" fontWeight="bold">
              {t("room.risks")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={t("room.sp_tooltip")} arrow>
              <Typography color="secondary" variant="h6" fontWeight="bold">
                {t("room.sp")}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            border: task.id === voting.task ? 1 : 0,
            borderRadius: 1,
            borderColor: "primary.main",
            position: "relative",
            marginBottom: "5px",
          }}
        >
          <Grid item xs={12}>
            <Accordion
              expanded={expanded}
              onChange={onExpand(task.id)}
              disableGutters
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container>
                  <Grid item xs={3}>
                    {task.link ? (
                      <Link
                        href={task.link}
                        target="_blank"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {task.name}
                      </Link>
                    ) : (
                      task.name
                    )}
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ backgroundColor: getColor(task.size) }}
                  ></Grid>
                  <Grid
                    item
                    xs
                    sx={{ backgroundColor: getColor(task.participation) }}
                  ></Grid>
                  <Grid
                    item
                    xs
                    sx={{ backgroundColor: getColor(task.risks) }}
                  ></Grid>
                  <Grid item xs={1}>
                    {task.value ? task.value : "-"}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {task.assignee &&
                  task.priority &&
                  task.status &&
                  task.component && (
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography>{task.assignee}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{task.priority}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{task.status}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{task.component}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{task.value ?? "-"}</Typography>
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={12} sx={{ margin: "20px 0 10px" }}>
                  <TaskItem
                    task={task}
                    onSave={onSave}
                    onDelete={onDelete}
                    metrics={metrics}
                    isEdit
                    disabled={!admins.some((a) => a.id === user.id)}
                  />
                </Grid>
                {admins.some((a) => a.id === user.id) && (
                  <Grid item xs={12}>
                    <AdminPanel
                      task={task}
                      onStart={onStart}
                      onEnd={onEnd}
                      onApply={onApply}
                    />
                  </Grid>
                )}
                {task.risks && task.participation && task.size && (
                  <Grid item xs={12}>
                    <Recommended
                      metrics={{
                        size: task.size,
                        risks: task.risks,
                        parts: task.participation,
                      }}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
      {voting.status && <VotePanel onVote={onVote} task={task} onEnd={onEnd} />}
    </>
  );
};
