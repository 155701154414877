const tasksURL = process.env.REACT_APP_JIRA_WITH_VPN + "/tasks";
const sprintsURL = process.env.REACT_APP_JIRA_WITH_VPN + "/sprints";

export const getJiraDevServerStatus = async () => {
  if (!process.env.REACT_APP_JIRA_WITH_VPN) return false;
  const response = await fetch(tasksURL, {
    headers: {
      Authorization: localStorage.getItem("api_token") ?? "",
    },
  });
  const data = await response.json();
  if (data.error) throw new Error(data.error);
  return data;
};
export const getJiraData = async (value: {
  rapidView: string;
  sprintId: string;
}) => {
  if (!process.env.REACT_APP_JIRA_WITH_VPN) return false;
  const response = await fetch(
    `${tasksURL}?rapidView=${value.rapidView}&sprintId=${value.sprintId}`,
    {
      headers: {
        Authorization: localStorage.getItem("api_token") ?? "",
      },
    }
  );
  const data = await response.json();
  return data;
};
export const getSprints = async (value: {
  rapidView: string;
  team?: string;
}) => {
  if (!process.env.REACT_APP_JIRA_WITH_VPN) return false;
  const response = await fetch(
    `${sprintsURL}?rapidView=${value.rapidView}${
      value.team ? `&team=${value.team}` : ""
    }`,
    {
      headers: {
        Authorization: localStorage.getItem("api_token") ?? "",
      },
    }
  );
  const data = await response.json();
  return data.sprints;
};
