import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme as appTheme, types } from "./tools";
import { QueryClient, QueryClientProvider } from "react-query";
import { styled } from "@mui/material";
import { Sidebar, Main } from "./components";
import { SnackbarProvider } from "notistack";
import { Room } from "./components";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { context, hooks } from "./tools";
import { Metrics } from "./components/Room/Metrics";

const queryClient = new QueryClient();
const App: React.FC = () => {
  const systemThemeMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? appTheme.DARK_MODE_THEME
      : appTheme.LIGHT_MODE_THEME;
  const [mode, setMode] = React.useState<
    typeof appTheme.LIGHT_MODE_THEME | typeof appTheme.DARK_MODE_THEME
  >((localStorage.getItem("theme") as "light" | "dark") ?? systemThemeMode);
  const themeMode = React.useMemo(
    () => ({
      toggleThemeMode: () => {
        let newTheme: "light" | "dark";
        setMode((prevMode) => {
          newTheme =
            prevMode === appTheme.LIGHT_MODE_THEME
              ? appTheme.DARK_MODE_THEME
              : appTheme.LIGHT_MODE_THEME;
          return newTheme;
        });
        setTimeout(() => localStorage.setItem("theme", newTheme));
      },
    }),
    []
  );
  const theme = React.useMemo(() => appTheme.getAppTheme(mode), [mode]);

  const { pathname } = useLocation();
  const [user, setUser] = hooks.useUser();
  return (
    <appTheme.ThemeModeContext.Provider value={themeMode}>
      <SnackbarProvider maxSnack={3} preventDuplicate autoHideDuration={3000}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Wrapper>
            <Sidebar user={user} setUser={setUser} />
            <ContentWrapper>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route
                    path="/"
                    element={<Main user={user} setUser={setUser} />}
                  />
                  <Route path="/metrics" element={<Metrics />} />

                  <Route
                    path="/room/:roomId"
                    element={
                      user && user.name && user.id ? (
                        <context.RoomProvider>
                          <Room />
                        </context.RoomProvider>
                      ) : (
                        <Navigate to="/" state={{ prev: pathname }} />
                      )
                    }
                  />
                </Routes>
              </QueryClientProvider>
            </ContentWrapper>
          </Wrapper>
        </ThemeProvider>
      </SnackbarProvider>
    </appTheme.ThemeModeContext.Provider>
  );
};
const Wrapper = styled("div")`
  margin: 20px auto;
  max-width: 1180px;
`;
const ContentWrapper = styled("div")`
  margin: 20px 0;
`;
export default App;
