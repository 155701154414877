import React from "react";
import { types, consts } from "../../tools";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  TextField,
  Stack,
  styled,
  Grid,
  DialogActions,
  Slide,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export type PreviewProps = {
  values: any;
  onClick: () => void;
  isSelected: boolean;
};
const PreviewContainer = styled("div")(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: "pointer",
  margin: "auto 10px",
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: "4px",
  "&:hover": {
    outline: `1px solid ${theme.palette.secondary.main}`,
  },
  "&.selected": {
    outline: `2px solid ${theme.palette.secondary.main}`,
  },
}));
const compareObjects = (
  object1: { [key: string]: string },
  object2: { [key: string]: string }
) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};
export const Settings: React.FC<types.RoomSettingsProps> = ({
  onSave,
  value,
}) => {
  const { t } = useTranslation();
  const [isPrivate, setIsPrivate] = React.useState<boolean>(
    value && value.password ? value.password.length > 0 : false
  );
  const [password, setPassword] = React.useState<string>(value.password ?? "");
  const [selectedMetrics, setSelectedMetrics] = React.useState<
    number | undefined
  >(
    value.metrics
      ? consts.keyValues.findIndex((e) => compareObjects(e, value.metrics))
      : undefined
  );
  const [selectedCards, setSelectedCards] = React.useState<number | undefined>(
    value.cards
      ? Object.values(consts.spValues).findIndex(
          (e) => JSON.stringify(e) === JSON.stringify(value.cards)
        )
      : undefined
  );
  const Preview = (props: PreviewProps) => {
    const { values, onClick, isSelected } = props;
    return (
      <PreviewContainer className={isSelected ? "selected" : ""}>
        {Array.isArray(values) ? (
          <div onClick={onClick}>
            <Typography>
              {t(`tasksComponent.tasks_cards.${values[0]}`)}
            </Typography>
            {values[1].map((v: string) => (
              <span style={{ padding: "2px 5px" }} key={v}>
                {v}
              </span>
            ))}
          </div>
        ) : (
          Object.entries(values).map((v) => (
            <div
              key={v[0]}
              style={{
                color: v[1] as string,
                fontWeight: "bold",
                padding: "3px 10px",
              }}
              onClick={onClick}
            >
              {t(`tasksComponent.tasks_metrics.${v[0]}`)}
            </div>
          ))
        )}
      </PreviewContainer>
    );
  };
  const ref = React.useRef(null);
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} md>
          <FormGroup ref={ref}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={() => {
                    setPassword("");
                    setIsPrivate(!isPrivate);
                  }}
                  color="secondary"
                />
              }
              color="secondary"
              label={
                <Typography color="secondary">
                  {t(`tasksComponent.private`)}
                </Typography>
              }
              sx={{ mx: "auto" }}
            />
          </FormGroup>
          <Slide direction="right" container={ref.current} in={isPrivate}>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label={t(`tasksComponent.password`)}
              size="small"
              fullWidth
              autoComplete="off"
              color="secondary"
            />
          </Slide>
        </Grid>
        <Grid item xs={12} md>
          <Typography textAlign="center" color="secondary" variant="h5">
            {t(`tasksComponent.metrics`)}:
          </Typography>
          <Stack
            direction="row"
            textAlign="center"
            my={1}
            justifyContent="center"
          >
            {consts.keyValues.map((kv, i) => (
              <Preview
                values={kv}
                key={i}
                onClick={() =>
                  setSelectedMetrics((prev) => (prev === i ? undefined : i))
                }
                isSelected={selectedMetrics === i}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography textAlign="center" color="secondary" variant="h5">
            {t(`tasksComponent.cards`)}:
          </Typography>

          <Stack
            direction="column"
            textAlign="center"
            mx={1}
            justifyContent="center"
            spacing={1}
          >
            {Object.entries(consts.spValues).map((kv, i) => (
              <Preview
                values={kv}
                key={i}
                onClick={() =>
                  setSelectedCards((prev) => (prev === i ? undefined : i))
                }
                isSelected={selectedCards === i}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
      <DialogActions sx={{ m: 0, l: 1, pb: 0 }}>
        <Button
          onClick={() =>
            onSave({
              password,
              cards: Object.values(consts.spValues)[selectedCards ?? 0],
              metrics: consts.keyValues[selectedMetrics ?? 0],
            })
          }
          color="secondary"
          sx={{ mr: 1 }}
          variant="outlined"
          disabled={
            selectedCards === undefined || selectedMetrics === undefined
          }
        >
          {t("tasksComponent.next_button")}
        </Button>
      </DialogActions>
    </>
  );
};
