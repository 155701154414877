export const locale: "ru" | "en" =
  (localStorage.getItem("lng") as "ru" | "en") ?? "ru";
export const locales = {
  ru: {
    joined: "присоединился",
    leaved: "отсоединился",
  },
  en: {
    joined: "joined",
    leaved: "leaved",
  },
};
