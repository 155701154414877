import React from "react";
import { Grid, Typography } from "@mui/material";
import { keyValues } from "../../tools/consts";

const endPoint = process.env.REACT_APP_API_ENDPOINT;

const getColor = (value: string): string => {
  const colors = keyValues[1];
  return colors[value] as unknown as string;
};
export const Metrics: React.FC = () => {
  const [items, setItems] = React.useState<{ [key: string]: string[] }>({});
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${endPoint}/avg`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result: { [key: string]: string[] } = await response.json();
      setItems(result);
    };
    fetchData();
  }, [setItems]);
  return (
    <Grid container spacing={1} sx={{ maxWidth: "600px", margin: "auto" }}>
      {Object.entries(items).map((i, index) => {
        const item = JSON.parse(i[0]);
        return (
          <Grid item xs={12} key={index}>
            <Grid container>
              <Grid
                item
                xs
                sx={{ backgroundColor: getColor(item.size) }}
              ></Grid>
              <Grid
                item
                xs
                sx={{ backgroundColor: getColor(item.parts) }}
              ></Grid>
              <Grid
                item
                xs
                sx={{ backgroundColor: getColor(item.risks) }}
              ></Grid>
              <Grid item xs={1}>
                <Typography variant="h5" textAlign="center">
                  {item.value}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {i[1].length} items
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
