import React from "react";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { context, hooks } from "../../tools";
import Avatar from "avataaars";
import ReactFlipCard from "reactjs-flip-card";
import { useTranslation } from "react-i18next";

export const RoomUsers: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [user] = hooks.useUser();
  const { users, votingData: initVoting, voting } = context.useRoom();
  const { avg, ...votingData } = initVoting;
  const theme = useTheme();
  const { t } = useTranslation();
  const Item = styled(Paper)`
    padding: ${theme.spacing(1)};
    textalign: "center";
    color: ${theme.palette.secondary.main};
    &.current {
      background-color: ${theme.palette.mode === "dark"
        ? "#1A2027"
        : "rgb(230, 230, 230)"};
    }
    &.votind.not-voted {
      border: 1px solid ${theme.palette.error.main};
    }
    &.votind.voted {
      border: 1px solid ${theme.palette.secondary.main};
    }
  `;
  React.useEffect(() => setOpen(!voting.status), [voting]);
  return (
    <>
      <Typography variant="h5">{t("room.users")}:</Typography>

      <Grid container spacing={2}>
        {users.map((u) => (
          <Grid item xs={6} md={4} key={u.id}>
            <Item
              variant="outlined"
              className={
                u.id === user.id
                  ? "current"
                  : "" + voting.status
                  ? "voting"
                  : "" + !!votingData[u.id]
                  ? "voted"
                  : "not-voted"
              }
              sx={{
                border: voting.status
                  ? !!votingData[u.id]
                    ? `1px solid ${theme.palette.secondary.main}`
                    : `1px solid ${theme.palette.error.main}`
                  : "",
              }}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Stack
                    direction="column"
                    sx={{ marginRight: "10px", height: "40px" }}
                    spacing={1}
                  >
                    {/* <Avatar
                      avatarStyle="Circle"
                      style={{ width: "50px", height: "50px" }}
                      {...u.avatarProps}
                    /> */}
                    <Typography
                      textAlign="center"
                      variant="h6"
                      sx={{ marginY: "auto" }}
                    >
                      {u.name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  {/* <FlipCard
                    open={!voting.status && !!votingData[u.id]}
                    value={votingData[u.id]}
                  /> */}
                  {open && !!votingData[u.id] ? (
                    <Typography
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.secondary.main}`,
                        borderRadius: "5px",
                      }}
                      variant="h4"
                    >
                      {votingData[u.id] as string}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        border: `1px solid ${theme.palette.secondary.main}`,
                        borderRadius: "5px",

                        height: "100%",
                        background: `repeating-linear-gradient(-45deg, ${theme.palette.secondary.main} 0, ${theme.palette.secondary.main} 1px, transparent 1px, transparent 5px)`,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Item>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
// 123
