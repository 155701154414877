import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { types, hooks } from "..";
import { locale, locales } from "./helpers";

interface Props {
  children: React.PropsWithChildren<React.ReactNode>;
}
const endpoit = process.env.REACT_APP_API_ENDPOINT! as string;
const RoomContext = React.createContext<types.RoomContextType>({
  voting: { task: "", status: false },
  roomId: "",
  message: "",
  loading: false,
  users: [],
  admins: [],
  tasks: [],
  tasksUpdating: (): void => {},
  taskEdit: (): void => {},
  userUpdating: (): void => {},
  votingAction: (): void => {},
  startVoting: (): void => {},
  endVoting: (): void => {},
  applyVoting: (): void => {},
  votingData: {},
  metrics: {},
  cards: [],
});

export const useRoom = (): types.RoomContextType =>
  React.useContext(RoomContext);

export const RoomProvider = ({ children }: Props) => {
  const [cards, setCards] = React.useState<string[]>([]);
  const [metrics, setMetrics] = React.useState<{ [key: string]: string }>({});

  const [users, setUsers] = React.useState<types.User[]>([]);
  const [admins, setAdmins] = React.useState<types.User[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");

  const [voting, setVoting] = React.useState({ task: "", status: false });
  const [votingData, setVotingData] = React.useState<{
    [key: string]: string | types.AVGType;
  }>({});

  const [tasks, setTasks] = React.useState<types.Task[]>([]);

  const [socket, setSocket] = React.useState<Socket | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const [user] = hooks.useUser();

  const { roomId } = params as { roomId: string };

  hooks.useTimeout(
    () => {
      setMessage("");
    },
    3000,
    [message]
  );

  React.useEffect(() => {
    setLoading(true);
    const s = io(endpoit, {
      auth: {
        user,
        roomId,
      },
    });

    setSocket(s);

    s.on("connect", () => {
      setLoading(false);
    });

    s.on("connect-err", (msg) => {
      navigate("/", { state: { message: msg } });
      setMessage(msg);
    });
    s.on(
      "connected",
      (props: {
        users: types.User[];
        admins: types.User[];
        tasks: types.Task[];
        settings?: types.RoomSettings;
        currentTask: string;
      }) => {
        setLoading(false);
        setUsers(props.users);
        setAdmins(props.admins);
        setTasks(props.tasks);
        setCards(props.settings?.cards ?? []);
        setMetrics(props.settings?.metrics ?? {});
      }
    );
    return () => {
      s.close();
    };
  }, [navigate, roomId, user]);

  React.useEffect(() => {
    if (!socket) return;

    socket.on("user-connected", (newUser: types.User) => {
      if (!users.find((u) => u.id === newUser.id))
        setUsers((current) => [...current, newUser]);
      setMessage(`${newUser.name} ${locales[locale].joined}`);
    });

    socket.on("user-disconnected", (id: string) => {
      const user = users.find((u) => u.id === id);
      setUsers((current) => current.filter((e) => e.id !== id));
      setMessage(`${user!.name} ${locales[locale].leaved}`);
    });

    socket.on("voting-started", (task: string) => {
      setVoting({ task: task, status: true });
      setVotingData({});
    });
    socket.on(
      "voting-ended",
      (result: {
        data: { [key: string]: string };
        task: string;
        avg: {
          recommended: string;
          borders: string[];
          average: number;
        };
      }) => {
        setVoting({ task: result.task, status: false });
        setVotingData({ ...result.data, avg: result.avg });
      }
    );
    socket.on("user-updated", (users: types.User[]) => {
      setUsers(users);
    });

    socket.on("user-voted", (data: { [key: string]: string }) => {
      setVotingData(data);
    });

    socket.on("tasks-updated", (tasks: types.Task[]) => {
      console.log(tasks);
      setTasks(tasks);
    });

    return () => {
      socket.off("user-connected");
      socket.off("user-disconnected");
      socket.off("voting-started");
      socket.off("voting-ended");
      socket.off("tasks-updated");
      socket.off("voted");
      socket.off("user-voted");
      socket.off("user-updated");
    };
  }, [socket, user.name, users]);

  const taskEdit = React.useCallback(
    (
      taskId: string,
      options: {
        value?: string;
        size?: string;
        participation?: string;
        risks?: string;
      }
    ): void => {
      socket?.emit("task-edit", taskId, options);
    },
    [socket]
  );

  const tasksUpdating = React.useCallback(
    (tasks: types.Task[]): void => {
      socket?.emit("tasks-updating", tasks);
    },
    [socket]
  );

  const userUpdating = React.useCallback(
    (user: types.User): void => {
      socket?.emit("user-update", user);
    },
    [socket]
  );

  const votingAction = React.useCallback(
    (userId: string, vote: string): void => {
      socket?.emit("user-voting", userId, vote);
    },
    [socket]
  );
  const startVoting = React.useCallback(
    (id: string) => {
      socket?.emit("voting-start", id);
    },
    [socket]
  );
  const endVoting = React.useCallback(
    (id: string) => {
      socket?.emit("voting-end", id);
    },
    [socket]
  );
  const applyVoting = React.useCallback(
    (id: string, value: string) => {
      setVoting({ task: "", status: false });
      socket?.emit("voting", localStorage.getItem("api_token"), id, value);
    },
    [socket]
  );

  const usersValue = React.useMemo<types.UsersContextType>(
    () => ({
      users,
      userUpdating,
      message,
      loading,
      admins,
      cards,
      metrics,
    }),
    [users, cards, metrics, message, loading, admins, userUpdating]
  );
  const voteValue = React.useMemo<types.VoteContextType>(
    () => ({
      voting,
      votingAction,
      startVoting,
      endVoting,
      applyVoting,
      votingData,
    }),
    [voting, votingAction, startVoting, endVoting, applyVoting, votingData]
  );

  const tasksValue = React.useMemo<types.TasksContextType>(
    () => ({
      taskEdit,
      tasksUpdating,
      tasks,
      roomId,
    }),
    [taskEdit, tasksUpdating, tasks, roomId]
  );
  return (
    <RoomContext.Provider
      value={{ ...usersValue, ...tasksValue, ...voteValue, roomId }}
    >
      {children}
    </RoomContext.Provider>
  );
};
