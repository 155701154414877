import {
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { context, hooks, types } from "../../tools";
import { DataItem } from "./";
import { useTranslation } from "react-i18next";
import { RoomHeader } from "./RoomHeader";
import { RoomUsers } from "./RoomUsers";
import { TaskItem } from "../TasksListComponent/TaskItem";
import { emptyTask } from "../TasksListComponent";
import { v4 as uuidV4 } from "uuid";
import { Recommended } from "./Recommended";

export const Room = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { loading, tasks, votingData, admins, tasksUpdating } =
    context.useRoom();

  let { avg } = votingData;
  avg = avg as unknown as types.AVGType;

  const StyledWrapper = styled(Box)`
    text-align: center;
    color: ${theme.palette.secondary.main};
  `;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [user] = hooks.useUser();
  const [isAdding, setIsAdding] = React.useState(false);

  const onExpand =
    (task: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
      setExpanded(isExpanded ? task : false);

  const onAddTask = (task: types.Task) => {
    tasksUpdating([...tasks, { ...task, id: uuidV4() }]);
  };
  return (
    <StyledWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Grid container>
          <Grid item xs={12} md={tasks.length ? 7 : 12}>
            <RoomHeader />
            <RoomUsers />
          </Grid>
          {!!tasks.length && (
            <Grid item xs={12} md={5}>
              <>
                <Typography variant="h4" gutterBottom noWrap>
                  {t("room.tasks")}:
                </Typography>
              </>
              <Grid container>
                <Grid item xs={12}>
                  <DataItem task={{} as types.Task} />
                </Grid>
                {tasks.map((t, i) => (
                  <Grid item key={i} xs={12}>
                    <DataItem
                      task={t}
                      expanded={expanded === t.id}
                      onExpand={onExpand}
                    />
                  </Grid>
                ))}
                {admins.some((a) => a.id === user.id) && (
                  <>
                    <Grid item xs={12} textAlign="end">
                      {isAdding ? (
                        <TaskItem
                          task={emptyTask}
                          onSave={onAddTask}
                          onDelete={() => setIsAdding(false)}
                          isEdit={false}
                        />
                      ) : (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setIsAdding(true)}
                        >
                          Add
                        </Button>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </StyledWrapper>
  );
};
