import React, { useState } from "react";
import { Metrics } from "../../tools/types";
import { Grid } from "@mui/material";
const endPoint = process.env.REACT_APP_API_ENDPOINT;

type RecommendedResponse = { value: string; weight: number }[];
export type RecommendedProps = {
  metrics: Metrics;
};
export const Recommended: React.FC<RecommendedProps> = ({ metrics }) => {
  const [recommended, setRecommended] = useState<RecommendedResponse>([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${endPoint}/avg/recommended`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...metrics,
        }),
      });
      const result: RecommendedResponse = await response.json();
      setRecommended(result);
    };
    fetchData();
  }, [metrics]);
  return (
    <>
      {recommended.length ? (
        recommended.map((r) => (
          <Grid item xs>
            value:{r.value} weight: {r.weight}
          </Grid>
        ))
      ) : (
        <>no data</>
      )}
    </>
  );
};
