/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect, Dispatch, SetStateAction, useMemo } from "react";
import { User } from "../types";

const getUser = (initial?: User): User => {
  const u = localStorage.getItem("user");
  if (!u) return initial ?? ({} as User);
  return JSON.parse(u);
};

export const useUser = (): [User, Dispatch<SetStateAction<User>>] => {
  const [user, setUser] = useState<User>(() => getUser());

  useEffect(() => {
    if (!user.name) localStorage.removeItem("user");
    else localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const currentUser = useMemo<User>(
    () => ({
      id: user.id,
      name: user.name,
      avatarProps: user.avatarProps,
    }),
    [user.avatarProps, user.id, user.name]
  );

  return [currentUser, setUser];
};
