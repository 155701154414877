import React from "react";
import { Checkbox, Grid, Link, Typography } from "@mui/material";
import { types } from "../../tools";

export const JiraTasksGrid: React.FC<types.JiraTasksGridProps> = ({
  tasks,
  label,
  selected,
  onSelect,
}) => {
  return (
    <Grid container mt={2} spacing="5px">
      <Typography variant="h6" fontWeight="bold" color="secondary">
        {label}
      </Typography>
      {tasks.map((task, i) => (
        <Grid
          container
          key={i}
          mb={2}
          onClick={() => onSelect(task)}
          spacing={1}
        >
          <Grid item xs={"auto"}>
            <Checkbox
              size="small"
              sx={{ p: 0, mr: 1 }}
              checked={!!selected.find((e) => e.id === task.id)}
              onChange={() => onSelect(task)}
              color="secondary"
            />
          </Grid>
          <Grid item xs={4} md="auto">
            <Link
              href={task.link}
              target="_blank"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {task.name}
            </Link>
          </Grid>
          <Grid item xs={7} md>
            <Typography color="secondary.dark">{task.title}</Typography>
          </Grid>
          <Grid item xs={4} md={1}>
            <Typography color="secondary.dark">{task.status}</Typography>
          </Grid>
          <Grid item xs={4} md={2.5}>
            <Typography color="secondary.dark">{task.assignee}</Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <Typography color="secondary.dark">
              {task.storyPoint ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <Typography color="secondary.dark">{task.type}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
