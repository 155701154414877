import { types } from ".";

export const keyValuesTemp = ["small", "medium", "large"];
export const keyValues5 = ["small", "medium", "large"];
export const spValues: { [key: string]: string[] } = {
  fib: ["1", "2", "3", "5", "8", "13", "21", "34"],
  shirts: ["?", "XS", "S", "M", "L", "XL", "XXL"],
  degrees: ["0", "1", "2", "4", "8", "16", "32"],
};

const smallKeyValues = {
  small: "green",
  medium: "yellow",
  large: "red",
};
const largeKeyValues = {
  extraSmall: "green",
  small: "limegreen",
  medium: "yellow",
  large: "orange",
  extraLarge: "red",
};

export const keyValues: { [key: string]: string }[] = [
  smallKeyValues,
  largeKeyValues,
];

export const defaultSettings: types.RoomSettings = {
  cards: spValues.fib,
  metrics: smallKeyValues,
};

const avatarConfig: { [key: string]: string[] } = {
  topType: [
    "NoHair",
    "Eyepatch",
    "Hat",
    "Hijab",
    "Turban",
    "WinterHat1",
    "WinterHat2",
    "WinterHat3",
    "WinterHat4",
    "ShortHairShortCurly",
    "ShortHairShortFlat",
    "ShortHairDreads01",
    "ShortHairDreads02",
  ],
  accessoriesType: ["Blank", "Prescription02", "Sunglasses"],
  hatColor: ["Black", "Red"],
  hairColor: ["Black", "Blonde", "Brown", "BrownDark"],
  facialHairType: ["Blank", "BeardLight", "MoustacheFancy"],
  clotheType: ["BlazerShirt", "Hoodie"],
  clotheColor: ["Black", "Red", "White"],
  skinColor: ["Light"],
};
const femaleAvatarConfig: { [key: string]: string[] } = {
  topType: [
    "LongHairBigHair",
    "LongHairBob",
    "LongHairBun",
    "LongHairCurly",
    "LongHairCurvy",
    "LongHairDreads",
    "LongHairFrida",
    "LongHairFro",
    "LongHairFroBand",
    "LongHairNotTooLong",
    "LongHairShavedSides",
    "LongHairMiaWallace",
    "LongHairStraight",
    "LongHairStraight2",
    "LongHairStraightStrand",
  ],
  accessoriesType: ["Blank", "Prescription02", "Sunglasses"],
  hatColor: ["Black", "Red"],
  hairColor: ["Black", "Blonde", "Brown", "BrownDark"],
  facialHairType: ["Blank", "BeardLight", "MoustacheFancy"],
  clotheType: ["BlazerShirt", "Hoodie"],
  clotheColor: ["Black", "Red", "White"],
  skinColor: ["Light"],
};

export const generateAvatar = () => {
  const options: { [key: string]: string } = {};
  Object.keys(avatarConfig).forEach((key) => {
    const configArray = avatarConfig[key];
    options[key] = configArray[Math.floor(Math.random() * configArray.length)];
  });
  return options;
};
