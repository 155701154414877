import React from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Logout,
  Edit,
  Save,
  Settings,
  Refresh,
} from "@mui/icons-material";
import { theme as appTheme, consts } from "../../tools";
import {
  styled,
  Typography,
  useTheme,
  IconButton,
  Grid,
  ListItemIcon,
  MenuItem,
  Menu,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Slide,
} from "@mui/material";
import { ReactComponent as ReactLogo } from "./icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { types } from "../../tools";
import { useTranslation } from "react-i18next";
import ToggleIcon from "material-ui-toggle-icon";
import { TransitionProps } from "@mui/material/transitions";
import Avatar from "avataaars";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const Sidebar: React.FC<types.SidebarProps> = ({ user, setUser }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { toggleThemeMode } = React.useContext(appTheme.ThemeModeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [locale, setLocale] = React.useState(i18n.language);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const [userName, setUserName] = React.useState(user.name);
  const [openModal, setOpenModal] = React.useState(false);
  const [apiToken, setApiToken] = React.useState(
    localStorage.getItem("api_token") ?? ""
  );
  const handleModalOpen = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setApiToken(localStorage.getItem("api_token") ?? "");
    setOpenModal(false);
  };
  const onSetToken = () => {
    localStorage.setItem("api_token", apiToken);
    setOpenModal(false);
  };
  const textInput = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const navigateToMain = () => {
    navigate("/");
  };
  const onLogout = () => {
    setUser({} as types.User);
    navigateToMain();
  };
  const location = useLocation();
  const isRoom = React.useMemo(() => location.pathname !== "/", [location]);
  const handleChangeLng = (e: React.MouseEvent<HTMLElement>, lng: string) => {
    if (lng !== null) {
      i18n.changeLanguage(lng);
      localStorage.setItem("lng", lng);
      setLocale(lng);
    }
  };
  const onRename = () => {
    if (isRenaming) setUser({ ...user, name: userName });
    setIsRenaming(!isRenaming);
  };
  const onAvatarUpdate = () => {
    const newAvatar = consts.generateAvatar();
    setUser({ ...user, avatarProps: newAvatar });
  };
  React.useEffect(() => {
    setIsRenaming(false);
    setUserName(user.name);
  }, [user, anchorEl]);
  return (
    <Grid container alignItems={"baseline"}>
      <Grid item xs>
        <Typography
          variant="h5"
          component="div"
          sx={{ flexGrow: 1 }}
          fontWeight="bold"
          color="secondary"
        >
          <StyledIcon fill={theme.palette.primary.main} />
          <StyledLink onClick={navigateToMain}>{t("sidebar.title")}</StyledLink>
        </Typography>
      </Grid>
      <Grid item sx={{ margin: "auto 5px" }}>
        <IconButton onClick={toggleThemeMode} size="small" ref={containerRef}>
          <ToggleIcon
            on={theme.palette.mode === appTheme.LIGHT_MODE_THEME}
            onIcon={<DarkModeOutlined color="secondary" />}
            offIcon={<LightModeOutlined color="secondary" />}
          />
        </IconButton>
      </Grid>
      <Grid
        item
        alignContent="center"
        textAlign="center"
        sx={{ marginY: "auto" }}
      >
        <ToggleButtonGroup
          color="primary"
          value={locale}
          exclusive
          onChange={handleChangeLng}
        >
          <ToggleButton value="ru" color="secondary" size="small">
            RU
          </ToggleButton>
          <ToggleButton value="en" color="secondary" size="small">
            EN
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {user.name && (
        <Grid item xs={"auto"} textAlign="center">
          <>
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                avatarStyle="Circle"
                style={{ width: "60px", height: "60px" }}
                {...user.avatarProps}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {!isRoom && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    mb: "5px",
                    mx: "5px",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsRenaming(!isRenaming);
                      if (isRenaming) onRename();
                      setTimeout(() => {
                        if (textInput && textInput.current)
                          textInput.current.focus();
                      }, 100);
                    }}
                    sx={{ marginX: "5px" }}
                  >
                    <ToggleIcon
                      on={isRenaming}
                      onIcon={<Save />}
                      offIcon={<Edit />}
                    />
                  </IconButton>
                  <TextField
                    variant="standard"
                    sx={{ marginY: "auto", width: "100px" }}
                    inputRef={textInput}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    size="small"
                    color="secondary"
                    autoComplete="off"
                    error={!!userName && userName.length === 0}
                    disabled={!isRenaming}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </Box>
              )}

              {!isRoom && <Divider sx={{ marginY: "8px" }} />}

              <MenuItem onClick={handleModalOpen}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                {t("sidebar.api_token.title")}
              </MenuItem>
              <Divider />
              <MenuItem onClick={onAvatarUpdate}>
                <ListItemIcon>
                  <Refresh fontSize="small" />
                </ListItemIcon>
                {t("sidebar.avatar_update")}
              </MenuItem>
              <Divider />

              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("sidebar.logout")}
              </MenuItem>
            </Menu>
          </>
        </Grid>
      )}
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        <DialogTitle>{t("sidebar.api_token.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("sidebar.api_token.content")}
          </DialogContentText>
          <TextField
            autoFocus
            label={t("sidebar.api_token.textfield")}
            fullWidth
            variant="standard"
            value={apiToken}
            onChange={(e) => setApiToken(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>
            {t("sidebar.api_token.cancel_button")}
          </Button>
          <Button onClick={onSetToken}>
            {t("sidebar.api_token.save_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
const StyledIcon = styled(ReactLogo)`
  transition: 0.5s;
  vertical-align: middle;
  height: 100%;
  margin-right: 10px;
  &:hover {
    transform: rotateZ(180deg);
  }
`;
const StyledLink = styled("span")`
  cursor: pointer;
`;
