import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { consts, types } from "../tools";
import { TasksListComponent } from "./TasksListComponent";
import { useTranslation } from "react-i18next";

const endPoint = process.env.REACT_APP_API_ENDPOINT;

export const Main: React.FC<types.MainProps> = ({ user, setUser }) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [roomId, setRoomId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [advancedMode, setAdvancedMode] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const navigate: NavigateFunction = useNavigate();
  const { state } = useLocation();

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvancedMode(event.target.checked);
  };
  useEffect(() => {
    return () => {
      setName("");
      setRoomId("");
      setError("");
    };
  }, []);

  const createUser = () => {
    setUser({
      id: uuidV4(),
      name,
      avatarProps: consts.generateAvatar(),
    });
    if (state && state.prev) setTimeout(() => navigate(state.prev));
  };

  const createRoom = async (
    tasks?: types.Task[],
    settings?: types.RoomSettings
  ): Promise<void> => {
    try {
      const response = await fetch(`${endPoint}/create-room`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user,
          tasks: tasks ?? [],
          settings: settings ?? {},
        }),
      });
      const { roomId: rID }: { message: string; roomId: string } =
        await response.json();
      navigate(`/room/${rID}`);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  const joinRoom = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setError("");
    if (!roomId) {
      setError("Please enter a valid Room ID");
      return;
    }
    try {
      const response = await fetch(`${endPoint}/join-room/${roomId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const data = await response.json();
      if (!response.ok) {
        const { error: e } = data;
        return setError(e);
      }
      navigate(`/room/${roomId}`);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  const onModalChange = () => setOpenModal(!openModal);
  const onAdvancedCreation = (
    tasks: types.Task[],
    settings?: types.RoomSettings
  ) => {
    setOpenModal(false);
    createRoom(tasks, settings);
  };
  const containerRef = React.useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: "error" });
  }, [enqueueSnackbar, error]);
  return (
    <Grid container textAlign="center">
      <Grid item xs={12}>
        {!user.name && (
          <>
            <StyledTitle variant="h5" textAlign="center" color="secondary">
              {t("main.your_name")}
            </StyledTitle>
            <TextField
              label={t("main.name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              color="secondary"
              autoComplete="off"
            />
            <Button color="secondary" onClick={createUser} sx={{ ml: 1 }}>
              {t("main.submit")}
            </Button>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={openModal ? 3 : 6}
        sx={{
          transition: "all .5s ease-in-out",
        }}
      >
        <StyledTitle variant="h5" textAlign="center" color="secondary">
          {t("main.join_room_title")}
        </StyledTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          {!openModal && (
            <>
              <TextField
                label={t("main.enter_room_id")}
                value={roomId}
                onChange={(e) => setRoomId(e.target.value)}
                disabled={!user.name}
                size="small"
                color="secondary"
                autoComplete="off"
              />
            </>
          )}
          <Button
            color="secondary"
            variant="outlined"
            disabled={!user.name}
            onClick={openModal ? () => setOpenModal(!openModal) : joinRoom}
            sx={{
              ml: 1,
            }}
          >
            {t("main.join_room")}
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={openModal ? 9 : 6}
        sx={{
          transition: "all .5s ease-in-out",
          margin: "auto",
        }}
      >
        {!(advancedMode && openModal) ? (
          <>
            <StyledTitle variant="h5" textAlign="center" color="secondary">
              {t("main.create_room_title")}
            </StyledTitle>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={t("main.advanced_tooltip")} arrow>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!user.name}
                      value={advancedMode}
                      onChange={onCheck}
                      color="secondary"
                    />
                  }
                  label={
                    <Typography
                      color="secondary"
                      variant="subtitle2"
                      sx={{ opacity: !user.name ? "0.4" : "1" }}
                    >
                      {t("main.advanced_mode")}
                    </Typography>
                  }
                />
              </Tooltip>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!user.name}
                onClick={
                  advancedMode
                    ? onModalChange
                    : () => createRoom([], consts.defaultSettings)
                }
                size="small"
              >
                {t("main.create_room")}
              </Button>
            </Stack>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              padding: 2,
              overflow: "hidden",
            }}
            ref={containerRef}
          >
            <Box sx={{ width: "100%" }}>
              <TasksListComponent onCreate={onAdvancedCreation} />
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
const StyledTitle = styled(Typography)`
  margin: 10px 0;
`;
