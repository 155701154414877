import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { context, types } from "../../tools";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { ContentCopyOutlined, QrCode } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { hooks, copy } from "../../tools";

type RouterParams = Readonly<Params<string>>;

export const RoomHeader: React.FC = () => {
  const { t } = useTranslation();
  const { message } = context.useRoom();
  const [open, setOpen] = React.useState(false);
  const params: RouterParams = useParams();
  const { roomId } = params;
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (message) enqueueSnackbar(message);
  }, [enqueueSnackbar, message]);
  const onCopy = () => {
    copy.copyToClipboard(document.location.href, {
      snackbar: enqueueSnackbar,
    });
  };
  const handleClick = () => setOpen(!open);
  return (
    <>
      <Typography variant="h4" gutterBottom noWrap>
        {t("room.room_id")}: {roomId}
        <Tooltip title={t("room.copy")} arrow>
          <IconButton size="small" color="primary" onClick={onCopy}>
            <ContentCopyOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("room.qr_code")} arrow>
          <IconButton size="small" color="primary" onClick={handleClick}>
            <QrCode fontSize="small" />
          </IconButton>
        </Tooltip>
        <Dialog open={open} onClose={handleClick} maxWidth="xs">
          <DialogContent>
            <QRCode
              size={256}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
              value={document.location.href}
              viewBox={`0 0 256 256`}
            />
          </DialogContent>
        </Dialog>
      </Typography>
    </>
  );
};
