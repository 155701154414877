import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { types } from "../../tools";
import { TaskItem } from "./TaskItem";
import { v4 as uuidV4 } from "uuid";
import { JiraView } from "./JiraView";
import { useTranslation } from "react-i18next";
import { Settings } from "./Settings";
import { Add } from "@mui/icons-material";

export const emptyTask: types.Task = {
  id: "",
  name: "",
  risks: "",
  participation: "",
  size: "",
  value: 0,
  title: "",
};

export const TasksListComponent: React.FC<types.CreateRoomModalProps> = ({
  onCreate,
  tasks: propTasks,
}) => {
  const { t } = useTranslation();
  const [tasks, setTasks] = React.useState<types.Task[]>(propTasks ?? []);
  const [activeStep, setActiveStep] = React.useState(0);
  const [jiraSelected, setJiraSelected] = React.useState<any[]>([]);
  const [settings, setSettings] = React.useState<types.RoomSettings>({});
  const [view, setView] = React.useState("default");
  const createNewTask = () => {
    setTasks([...tasks, { ...emptyTask, id: uuidV4() }]);
  };
  const onDelete = (id: string) => {
    setTasks((current) => current.filter((e) => e.id !== id));
  };
  const onSave = (newTask: types.Task) => {
    setTasks((current) =>
      current.map((e) => {
        if (e.id === newTask.id) return { ...newTask };
        return e;
      })
    );
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const onJiraSelect = (task: any) => {
    if (jiraSelected.findIndex((e) => e.id === task.id) === -1) {
      setJiraSelected([...jiraSelected, task]);
    } else {
      setJiraSelected(jiraSelected.filter((e) => e.id !== task.id));
    }
  };
  const onCreateHandler = () => {
    if (jiraSelected.length > 0) {
      onCreate(
        jiraSelected.map((e) => {
          return {
            id: e.id,
            name: e.name,
            size: "",
            participation: "",
            risks: "",
            value: e.storyPoint,
            link: e.link,
            title: e.title,
            assignee: e.assignee,
            component: e.component,
            status: e.status,
            priority: e.priority,
          };
        }),
        settings
      );
    } else onCreate(tasks, settings);
  };
  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{ textAlign: "start" }}
    >
      <Step
        sx={{
          "& .MuiStepLabel-root .Mui-completed": {
            color: "secondary.dark",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "grey.500",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "secondary.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "common.white",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        }}
      >
        <StepLabel color="secondary">
          {t("tasksComponent.second_step_label")}
        </StepLabel>
        <StepContent color="secondary">
          <DialogTitle sx={{ m: 0, p: 1 }} color="secondary"></DialogTitle>
          <DialogContent>
            <Settings
              onSave={(settings) => {
                setSettings(settings);
                handleNext();
              }}
              value={settings}
            />
          </DialogContent>
        </StepContent>
      </Step>
      <Step
        sx={{
          "& .MuiStepLabel-root .Mui-completed": {
            color: "secondary.dark",
          },
          "& .MuiStepLabel-root .Mui-disabled": {
            color: "secondary.dark",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "secondary.main",
          },
        }}
      >
        <StepLabel color="secondary">
          {t("tasksComponent.first_step_label")}
        </StepLabel>
        <StepContent color="secondary">
          <DialogTitle sx={{ m: 0, p: 1 }} color="secondary">
            <Grid container>
              <Grid xs={10} item textAlign={"start"}>
                {!tasks.length && (
                  <ToggleButtonGroup
                    color="secondary"
                    value={view}
                    exclusive
                    onChange={(e, newValue) => {
                      setView(newValue);
                      setJiraSelected([]);
                    }}
                    size="small"
                  >
                    <ToggleButton value="default" color="secondary">
                      {t("tasksComponent.default")}
                    </ToggleButton>
                    <ToggleButton value="jira" color="secondary">
                      {t("tasksComponent.with_jira")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {view === "default" && (
              <Grid container>
                <Button
                  onClick={createNewTask}
                  color="secondary"
                  startIcon={<Add />}
                >
                  {t("tasksComponent.create_task")}
                </Button>
                {tasks.map((t, i) => (
                  <Grid item xs={12} key={i} sx={{ m: 1 }}>
                    <TaskItem
                      task={t}
                      onSave={onSave}
                      onDelete={onDelete}
                      metrics={settings.metrics}
                      isEdit={false}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === "jira" && (
              <JiraView selected={jiraSelected} onSelect={onJiraSelect} />
            )}
          </DialogContent>

          <DialogActions sx={{ m: 0, l: 1, pb: 0 }}>
            <Button
              onClick={handleBack}
              color="secondary"
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t("tasksComponent.back_button")}
            </Button>
            <Button
              onClick={onCreateHandler}
              color="secondary"
              sx={{ mr: 1 }}
              variant="outlined"
              disabled={tasks.length === 0 && jiraSelected.length === 0}
            >
              {t("tasksComponent.create_room")}
            </Button>
          </DialogActions>
        </StepContent>
      </Step>
    </Stepper>
  );
};
